import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/pig66fjPEvs"
    bibleGroupSrc="https://pdfhost.io/v/.9mFfiL2D_Bible_Group_Homework_1129pdf.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="How to Pray - Holiday on its Head" />
  </Layout>
)

export default SermonPost
